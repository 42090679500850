

















































import { Component, Prop, Vue } from 'vue-property-decorator'
import PerPagePagination from '@/app/ui/components/PaginationNav/components/PerPagePagination/index.vue'
import PaginationNavigator from '@/app/ui/components/PaginationNav/components/PaginationNavigator/index.vue'

export interface IOption {
  label: string
  value: number
}

/**
 * page: number | string
 * The current page number
 *
 * perPage: number | string
 * The maximum number of rows per page to display
 *
 * totalItem: number | string
 * Total item of data
 *
 * enablePerPageOption: boolean
 * This will enable perPage option using dropdown instead of one predetermine perPage value
 *
 * perPageOptions: IOption[]
 * The perPage option for perPage dropdown.
 * The first index of the option will be set as the default selected option
 */

@Component({
  components: {
    PerPagePagination,
    PaginationNavigator,
  },
})
export default class PaginationNav extends Vue {
  @Prop() private page!: number | string
  @Prop() private perPage!: number | string
  @Prop() private totalItem!: number | string
  @Prop({ default: true }) private showRangeData!: boolean
  @Prop({ default: false }) private enablePerPageOption!: boolean
  @Prop({ default: () => [] }) private perPageOptions!: IOption[] | number[]
  @Prop({ default: false }) private isDisabledPerPageOptions!: boolean
  @Prop({ default: 'en' }) private lang!: 'en' | 'id'

  selected = this.options[0]

  localeText = {
    id: ['Menampilkan', 'dari', 'Data'],
    en: ['Show', 'of', 'data'],
  }

  onChangePerPageOption(optionValue: IOption): void {
    this.$emit('onChangeOption', optionValue.value)
    this.selected = optionValue
  }

  get options(): IOption[] {
    if (this.perPageOptions.length === 0) {
      return [{ label: '10', value: 10 }]
    }

    if (
      typeof this.perPageOptions[0] === 'number' ||
      typeof this.perPageOptions[0] === 'string'
    ) {
      return (<number[]>this.perPageOptions).map(num => {
        return {
          label: typeof num === 'number' ? <string>num.toString() : num,
          value: typeof num === 'string' ? <number>parseInt(num) : num,
        }
      })
    }
    return <IOption[]>this.perPageOptions
  }

  get perPageCount(): number {
    let count = this.perPage

    if (this.enablePerPageOption) {
      count = this.selected.value
    }

    return Number(count)
  }

  get perPageValue(): number {
    if (this.currentPage === this.totalPages) {
      if (this.perPageCount === this.totalItem) {
        return Number(this.perPageCount)
      }
      if (Number(this.totalItem) % Number(this.perPageCount) !== 0) {
        return Number(this.totalItem) % Number(this.perPageCount)
      }
    }
    return Number(
      this.perPageCount > this.totalItem ? this.totalItem : this.perPageCount
    )
  }

  get currentPage(): number {
    return Number(this.page) < 1 ? 1 : Number(this.page)
  }

  get totalPages(): number {
    return Math.ceil(Number(this.totalItem) / Number(this.perPageCount))
  }
}
